import { FunctionComponent } from 'react';

import {
  Avatar as AvatarMui,
  Box,
  BoxProps,
  CircularProgress,
  styled,
} from '@mui/material';

import { Author, User } from '@/ext/types';
import { useUser } from '../state/user';

export interface AvatarProps extends BoxProps {
  isLoading?: boolean;
  size?: number;
  user?: User | (Author & { userId?: number }) | undefined | null;
}

const Photo = styled(AvatarMui)(({ theme }) => ({
  color: theme.palette.common.white,

  [theme.breakpoints.down('sm')]: {
    height: 32,
    width: 32,
  },

  '&.no-subscription': {
    border: 'none',
  },

  '&.lite': {
    border: `2px solid ${theme.palette.text.disabled}`,
  },

  '&.pro': {
    border: `2px solid ${theme.palette.warning.main}`,
  },

  '&.ultra': {
    border: `2px solid ${theme.palette.warning.light}`,
  },

  '&.enterprise': {
    border: `2px solid ${theme.palette.text.disabled}`,
  },
}));

const Avatar: FunctionComponent<AvatarProps> = ({
  isLoading = false,
  size = 48,
  user,
  ...props
}) => {
  const currentUser = useUser();

  const sx = {
    height: size,
    width: size,
    fontSize: size - 4,
  };

  if (isLoading) {
    return (
      <Box {...props}>
        <CircularProgress size={16} />
      </Box>
    );
  }

  if (user?.thumbnail) {
    return (
      <Box {...props}>
        <Photo
          className={
            user.userId === currentUser?.userId
              ? user.subscriptions?.[0]?.name
              : 'no-subscription'
          }
          sx={sx}
          src={user.thumbnail}
        />
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Photo
        className={
          user?.userId === currentUser?.userId
            ? user?.subscriptions?.[0]?.name
            : 'no-subscription'
        }
        sx={sx}
      >
        {user?.name?.[0]?.toUpperCase()}
      </Photo>
    </Box>
  );
};

export default Avatar;
