import assert from 'assert';

import { WaldoAPI } from '../ext/lib/api';
import {
  Author,
  PresignedUrl,
  Subscription,
  User,
  UserEmail,
} from '../ext/types';

export class UserAPI extends WaldoAPI {
  private baseAuthUrl = '/v1/auth';

  private baseUserUrl = '/v1/users';

  async changePassword(newPassword: string): Promise<User | null> {
    return this.request({
      method: 'PUT',
      path: `${this.baseAuthUrl}/password/change`,
      body: {
        newPassword,
      },
    });
  }

  async changePrimaryEmail(emailId: number): Promise<UserEmail> {
    return this.request({
      method: 'PUT',
      path: `${this.baseAuthUrl}/email/${emailId}`,
    });
  }

  async createSecondaryEmail(email: string): Promise<UserEmail> {
    return this.request({
      method: 'POST',
      path: `${this.baseAuthUrl}/email`,
      body: {
        email,
      },
    });
  }

  async deleteAccount(userId: number): Promise<void> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUserUrl}/${userId}`,
    });
  }

  async deleteSecondaryEmail(emailId: number): Promise<UserEmail> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseAuthUrl}/email/${emailId}`,
    });
  }

  async getAuthor(authorId: number): Promise<Author | undefined> {
    return this.request({
      method: 'GET',
      path: `${this.baseUserUrl}/author/${authorId}`,
    });
  }

  async getPresignedUrl(
    bucketPrefix: string,
    file: File,
    bucketName: string,
  ): Promise<PresignedUrl> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUserUrl}/file_destination`,
      body: {
        bucketName,
        filename: `${bucketPrefix}/${file.name}`,
        filetype: file.type,
      },
    });
  }

  async getPresignedUrlV2(
    file: File,
    name: string,
    bucketName: string,
  ): Promise<PresignedUrl> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUserUrl}/file_destination`,
      body: {
        bucketName,
        filename: name,
        filetype: file.type,
      },
    });
  }

  async logout(): Promise<void> {
    return this.request({ method: 'POST', path: `${this.baseAuthUrl}/logout` });
  }

  async me(): Promise<{ user: User; jwt: string }> {
    const { data, headers } = await this.requestEnhanced<User>({
      method: 'GET',
      path: `${this.baseAuthUrl}/me`,
    });

    const jwt = headers['x-auth-token'];
    assert(jwt !== null, 'JWT is null');
    return {
      user: data,
      jwt,
    };
  }

  async updateUser(userId: number, data: Partial<User>): Promise<User | null> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUserUrl}/${userId}`,
      body: {
        data,
      },
    });
  }

  async getSubscriptions(): Promise<
    (Subscription & { teamName?: string; role?: string })[]
  > {
    return this.request({
      method: 'GET',
      path: `${this.baseUserUrl}/me/subscriptions`,
    });
  }
}
