import {
  atom,
  atomFamily,
  Loadable,
  noWait,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import dayjs from 'dayjs';
import { UserAPI } from '@/rpc/users';
import { Author, SubscriptionType, User } from '../../types';
import { setLocalStorageItem } from '@/ext/lib/storage';

export type ValueType = User | undefined;

const userApi = new UserAPI();

export const userFetchState = selector({
  key: 'userFetch',
  get: async () => {
    const user = userApi.me();
    setLocalStorageItem('isAuthenticated', 'true');
    return user;
  },
});

export const jwtState = atom({
  key: 'jwt',
  default: selector({
    key: 'jwtState/Default',
    get: ({ get }) => {
      try {
        const { jwt } = get(userFetchState);

        return jwt;
      } catch (e) {
        return undefined;
      }
    },
  }),
});

export const useJwt = (): string | undefined => useRecoilValue(jwtState);

export const userAsyncState = atom<ValueType>({
  key: 'userAsyncState',
  default: selector({
    key: 'userAsyncState/Default',
    get: ({ get }) => get(userFetchState).user,
  }),
});

export const userState = selector<ValueType>({
  key: 'userState',
  get: ({ get }) => get(noWait(userAsyncState)).valueMaybe(),
});

export const useSetUser = (): SetterOrUpdater<ValueType> =>
  useSetRecoilState(userAsyncState);

export const useUserLoadable = (): Loadable<ValueType> =>
  useRecoilValueLoadable(userAsyncState);

export const useUser = (): ValueType => useRecoilValue(userState);

export const useIsAIUser = (): boolean => {
  const { subscriptions } = useUser() || {};
  return (
    subscriptions?.some(
      (s) =>
        s.name !== SubscriptionType.pro &&
        s.name !== SubscriptionType.ultra &&
        !dayjs(s.startDate).isBefore('2023-05-18', 'day'),
    ) || false
  );
};

export const authorState = atomFamily({
  key: 'authorState',
  default: selectorFamily({
    key: 'authorLoader',
    get: (authorId?: number) => () =>
      authorId ? userApi.getAuthor(authorId) : undefined,
  }),
});

export const useAuthor = (authorId?: number): Author | undefined =>
  useRecoilValue(authorState(authorId));
