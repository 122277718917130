import { getUtmTags } from '@/lib/utils';
import { API_HOST } from '../constants';

export const signUpOktaUrl = (
  redirectUrl?: string,
  invite?: string,
  teamCode?: string,
  referrerUserId?: string | string[],
): string => {
  const url = new URL(`${API_HOST}/v1/auth/okta/signup`);
  if (typeof referrerUserId === 'string') {
    url.searchParams.append('referrerUserId', referrerUserId);
  }

  if (invite) {
    url.searchParams.append('invite', invite);
  }

  if (teamCode) {
    url.searchParams.append('teamCode', teamCode);
  }

  if (redirectUrl) {
    url.searchParams.append('redirect', redirectUrl);
  }

  const utm = getUtmTags();
  if (utm) {
    url.searchParams.append('utm', encodeURIComponent(utm));
  }

  return url.href;
};
